import React, { useContext, useRef, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./refer-a-friend.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"
import * as ReferIll from "~/vectors/refer.inline.svg"
import Col5 from "../grid/Col5"
import Col6 from "../grid/Col6"
import BlockCTA from "../configurable/BlockCTA"
import Col7 from "../grid/Col7"
import { GetModal } from "../configurable/Modal"


const content = {
  terms_and_conditions: {
    title: 'T&Cs',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >

            <p className="bold-formatting" >  You can refer a maximum of 15 customers to have an installation. Once their installation is complete and they have paid their invoice, we will refund you the difference from what you have claimed, to a maximum amount of £7000.
            </p>
          </div>

        </div>
      </>
    )
  },
}

const ReferAFriend = ({ location }) => {

  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000';
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);

  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);

  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {

        closeModal();
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  //END MODAL LOGIC


  //BUTTON COPY START



  //BUTTON COPY ENDS

  return (
    <div className="refer">
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={getModalTitle()}
      >
        {getModalContent()}
      </GetModal>
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Refer a Friend
        </Heading>
      </Hero>
      <Block>
        <Section>
          <div className="container">
            <Heading level={2} >
              About the scheme
            </Heading>
            <p className="bold-formatting" style={{marginBottom:25}}>
              It's never been easier to get rewarded for going green. For every person you refer who gets an installation we'll give you £250 cash! You can claim the cost of your installation back to the value of £7000.  </p>
            <Heading level={3} underlined>
              How to apply
            </Heading>
            <p className="bold-formatting">
              To start the process, we need you to fill out our Refer a Friend form. You can only apply for this scheme If have had a Solar PV system and/or Battery Storage installation done by Green Energy Together.
            </p>
            <div className="row y-align_row" style={{marginTop:0}}>
              <Col7 >
                <div>
                  <div className="refer__button__container">
                    <BlockCTA
                      external
                      url="https://forms.office.com/r/Tii2tykMXX"
                      arrow="right">
                      Join Scheme
                    </BlockCTA>
                  </div>
                  <p>
                    Once you’ve completed the form, you can send your referees to this web page by sharing our website URL with them.
                  </p>
                  <div className="refer__section second">
                    <Heading level={3} underlined>
                      What your friends need to do
                    </Heading>
                    <p className="bold-formatting">
                      All your friends / referees need to do is click on the button below, complete the form and then Green Energy Together will contact the referrer with regards to their payment.
                    </p>
                  </div>
                  <div className="refer__button__container">
                    <BlockCTA
                      external
                      url="https://forms.office.com/r/G2HStMsGwK"
                      arrow="right">
                      Referees Form
                    </BlockCTA>
                  </div>
                  <button className="terms_modal_btn" onClick={() => onOpenModalFor('terms_and_conditions')}>
                    For full terms and conditions <span>click here</span>.
                  </button>
                </div>
              </Col7>
              <Col5 className="y-align_item" style={{paddingTop:25}}>
                <ReferIll className="hide-under-1k" />
              </Col5>
            </div>
          </div>
        </Section>
      </Block>
    </div>
  )
}

export default ReferAFriend
